@media all {
  .page-break {
    display: fixed;
  }
}

@media print {
  html, body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    -moz-osx-font-smoothing: grayscale;
    height: initial !important;
  }

  .page-break {
    page-break-before: auto;
    display: block;
  }
}

@page {
  size: auto;
  margin: 12mm 4mm;
}

.printing-page {
  counter-reset: page-count;
}

.page-footer {
  counter-increment: page-count;
  display: table-footer-group;
}

.page-footer:after {
  content: counter(page-count);
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-ext-400-normal.5baf44ce.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-400-normal.6d360dd5.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-ext-400-normal.3b199b49.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-400-normal.94705851.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-vietnamese-400-normal.b7170924.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-ext-400-normal.65ba6c38.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.57844c26.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.50e95bcb.css.map */
