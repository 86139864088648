// Variables
@import 'variables';

@media all {
  .page-break {
    display: fixed;
  }
}

@media print {
  html, body {
    height: initial !important;
    // overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    -moz-osx-font-smoothing: grayscale;
  }
  .page-break {
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 12mm 4mm;
}

.printing-page {
  counter-reset: page-count;
}

.page-footer {
  display: table-footer-group;
  counter-increment: page-count;
}

.page-footer:after {
  content: counter(page-count);
}

